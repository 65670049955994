import React from 'react'

import MetaHelmet from '../components/common/meta_helmet'
import BlankLayout from '../components/layouts/blank_layout'
import P from '../components/common/paragraph'
import PageLink from '../components/common/page_link'
import TopBar from '../components/common/top_bar'

import META_IMAGE_SRC from '../images/seal.jpg'

const Page = () => (
  <BlankLayout>
    <MetaHelmet image={META_IMAGE_SRC} imageAlt="A barking seal" />

    <TopBar />

    <P>Hullo, I'm Junie.</P>

    <P>
      By day I'm a software engineer working in the care sector in the beautiful
      city of Edinburgh, Scotland. Also by day I write about technology. I
      create long(ish) form, highly interactive pieces that guide you through
      new concepts.
    </P>

    <P>Feel free to have a gander.</P>

    <PageLink
      pageID="bdd39156-3f7f-44ec-8aca-93ff25aea513"
      background="#2dbbe8"
      foreground="white"
    />
    <PageLink
      pageID="bff7b37e-205f-4ec1-90b5-c96cd13be512"
      background="#2e5cfc"
      foreground="white"
    />
    <PageLink
      pageID="23b91262-6fb4-41b9-9398-a3408cd63c2b"
      background="#ff8f2c"
      foreground="white"
    />
    <PageLink
      pageID="21cf2579-fed8-4b3c-b395-3177d4dcfb9c"
      background="#fc762e"
      foreground="white"
    />

    <P>
      My writing takes time and a lot of careful work. If you can spare anything
      to help me do it, I'll love you forever.
    </P>

    <PageLink
      pageID="bb55716b-3f98-4b78-93ad-c139e065b8d6"
      background="#fd22a3"
      foreground="white"
    />

    <P>I also keep a very light blog on this site! Check out the posts here:</P>

    <PageLink
      pageID="7a57656f-200b-46cc-a49c-4e11551b1a27"
      background="#446671"
      foreground="white"
    />

    {/* <P>
      Finally, there's a home here for some little one-shot experiments and
      projects:
    </P> */}
  </BlankLayout>
)

export default Page
